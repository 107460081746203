import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >

<path d="M5200 7305 c-381 -56 -721 -268 -931 -580 -217 -323 -289 -712 -200
-1082 91 -380 331 -691 681 -886 316 -175 737 -209 1088 -86 376 131 683 425
821 784 163 423 113 885 -135 1261 -73 109 -241 281 -349 356 -284 197 -642
282 -975 233z m464 -135 c271 -66 457 -174 646 -373 146 -155 257 -374 305
-602 24 -112 24 -362 1 -470 -55 -254 -155 -448 -318 -621 -78 -82 -188 -171
-258 -210 -19 -10 -37 -21 -40 -24 -12 -13 -171 -80 -232 -99 -181 -54 -424
-69 -598 -36 -414 80 -747 342 -914 719 -269 609 -4 1333 596 1624 100 49 257
94 403 116 78 12 322 -2 409 -24z"/>
<path d="M4902 6600 l-92 -189 285 -571 c157 -313 288 -570 293 -570 4 0 49
84 100 185 l93 186 -288 574 c-158 316 -289 575 -292 575 -4 0 -48 -85 -99
-190z"/>
<path d="M5672 6601 l-93 -188 286 -572 c157 -314 289 -571 293 -571 4 0 49
84 100 186 l93 186 -286 574 c-158 316 -290 574 -293 574 -4 -1 -49 -85 -100
-189z"/>
<path d="M4522 5838 c-51 -101 -92 -189 -92 -196 0 -18 179 -372 188 -372 8 0
185 356 185 374 0 12 -169 356 -182 369 -4 4 -49 -75 -99 -175z"/>
<path d="M4534 4501 c-44 -27 -61 -79 -40 -120 44 -85 160 -80 187 8 28 93
-64 163 -147 112z"/>
<path d="M1510 3995 l0 -506 88 3 87 3 5 365 c4 287 7 360 17 340 6 -14 57
-140 113 -280 55 -140 116 -293 135 -340 l34 -85 88 0 88 0 22 55 c12 30 28
71 36 90 8 19 63 158 122 308 59 150 109 271 111 269 3 -2 6 -166 7 -363 l2
-359 90 0 90 0 0 500 0 500 -129 0 c-104 0 -130 -3 -137 -15 -5 -8 -34 -80
-65 -160 -30 -80 -80 -206 -109 -280 -30 -74 -68 -172 -85 -217 -17 -46 -35
-83 -39 -83 -7 0 -163 388 -263 650 -17 47 -36 91 -41 98 -7 8 -47 12 -138 12
l-129 0 0 -505z"/>
<path d="M4176 4485 c-3 -9 -6 -84 -6 -165 l0 -149 -72 36 c-61 32 -84 37
-156 41 -73 4 -92 1 -151 -22 -274 -108 -343 -457 -128 -652 71 -64 142 -93
243 -101 77 -6 114 2 206 47 31 16 59 26 62 22 3 -3 6 -16 6 -29 0 -23 1 -24
88 -21 l87 3 0 500 0 500 -86 3 c-72 2 -88 0 -93 -13z m-128 -421 c215 -107
139 -434 -102 -434 -74 0 -147 38 -188 98 -27 39 -33 58 -36 115 -6 106 32
178 121 222 68 34 136 34 205 -1z"/>
<path d="M7017 4493 c-4 -3 -7 -78 -7 -165 0 -87 -2 -158 -5 -158 -3 0 -22 11
-43 24 -104 68 -250 75 -366 17 -193 -95 -275 -321 -186 -510 73 -154 228
-243 395 -228 66 6 97 17 158 55 43 26 45 26 50 -9 4 -23 7 -24 95 -27 l92 -3
0 506 0 505 -88 0 c-49 0 -92 -3 -95 -7z m-130 -428 c160 -80 170 -300 20
-402 -37 -25 -51 -28 -122 -28 -71 0 -84 3 -122 28 -63 43 -96 97 -101 167 -6
83 3 121 42 171 68 89 181 114 283 64z"/>
<path d="M8187 4444 c-4 -4 -7 -54 -7 -111 l0 -103 -70 0 -70 0 0 -80 0 -80
69 0 69 0 4 -229 3 -229 31 -39 c53 -65 85 -77 207 -82 l108 -3 -3 78 -3 79
-50 3 c-115 6 -116 9 -113 242 l3 175 80 5 80 5 3 77 3 77 -83 3 -83 3 -5 105
-5 105 -80 3 c-45 1 -84 0 -88 -4z"/>
<path d="M2968 4231 c-59 -19 -148 -67 -148 -79 1 -4 12 -25 26 -47 13 -22 27
-48 30 -57 8 -22 1 -23 67 7 74 34 171 43 220 21 38 -19 67 -66 67 -110 0 -29
-3 -30 -181 -44 -141 -10 -214 -40 -256 -104 -57 -85 -38 -205 42 -276 52 -45
100 -62 182 -62 76 0 140 22 190 65 l40 34 34 -35 c40 -41 86 -57 154 -52 l50
3 0 75 c0 74 0 75 -27 78 -14 2 -31 9 -36 15 -6 7 -12 98 -14 202 -3 175 -5
194 -26 237 -29 59 -85 108 -149 131 -64 23 -190 22 -265 -2z m259 -477 c-9
-88 -70 -134 -174 -134 -88 0 -120 30 -115 107 4 46 77 68 236 72 l59 1 -6
-46z"/>
<path d="M5094 4226 c-28 -13 -55 -29 -58 -35 -13 -21 -36 -12 -36 14 0 25 -1
25 -90 25 l-90 0 0 -370 0 -370 89 0 90 0 3 248 c3 225 5 250 23 280 23 38 87
72 135 72 48 0 112 -34 135 -72 18 -30 20 -55 23 -280 l3 -248 90 0 90 0 -3
278 -3 277 -28 55 c-31 60 -92 113 -155 136 -61 22 -159 17 -218 -10z"/>
<path d="M5810 4231 c-62 -20 -136 -56 -145 -71 -6 -10 16 -59 48 -101 l19
-27 57 26 c65 31 179 37 224 13 29 -16 57 -66 57 -104 0 -29 -8 -31 -180 -43
-197 -13 -290 -84 -290 -224 0 -126 107 -219 252 -220 81 0 152 24 199 68 l34
31 32 -34 c37 -39 92 -57 161 -53 l47 3 3 73 c3 76 4 74 -49 86 l-27 6 -3 203
-4 204 -30 49 c-33 53 -104 107 -163 124 -57 15 -180 11 -242 -9z m260 -463
c0 -88 -72 -148 -177 -148 -79 0 -117 27 -117 84 0 71 47 90 237 95 l57 1 0
-32z"/>
<path d="M7498 4227 c-36 -14 -81 -35 -100 -48 -18 -12 -34 -23 -36 -24 -1 -1
14 -29 34 -62 l36 -61 55 25 c96 44 214 41 258 -7 10 -11 20 -39 22 -64 6 -54
11 -52 -157 -61 -202 -12 -283 -63 -304 -191 -15 -88 34 -182 117 -227 35 -18
58 -22 137 -22 103 0 144 14 203 69 l27 26 39 -39 c40 -40 72 -51 153 -51 l49
0 -3 78 c-3 74 -4 77 -28 80 -49 7 -50 13 -50 202 0 207 -11 259 -69 317 -57
57 -112 76 -221 80 -81 3 -105 0 -162 -20z m270 -477 c-7 -75 -52 -117 -137
-131 -87 -13 -151 23 -151 86 0 68 50 88 234 93 l59 2 -5 -50z"/>
<path d="M8790 4231 c-71 -23 -140 -58 -146 -76 -3 -7 10 -38 30 -69 l35 -55
58 27 c47 22 73 27 135 27 70 0 80 -2 103 -26 28 -27 47 -89 35 -109 -5 -7
-55 -15 -131 -21 -146 -11 -190 -21 -244 -57 -99 -67 -116 -231 -32 -314 54
-54 96 -72 182 -76 91 -5 144 12 214 67 l37 30 26 -33 c30 -37 82 -56 157 -56
l51 0 0 80 0 80 -30 0 c-19 0 -33 7 -40 19 -6 12 -10 94 -10 197 0 205 -7 239
-65 299 -79 81 -233 109 -365 66z m255 -440 c8 -14 -13 -101 -29 -116 -38 -38
-82 -55 -145 -55 -57 0 -68 3 -93 27 -42 40 -35 97 15 125 39 22 240 37 252
19z"/>
<path d="M4500 3860 l0 -371 88 3 87 3 3 368 2 367 -90 0 -90 0 0 -370z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
